.hamburger {
  transition: transform 0.4s ease;
  width: 40px;
  height: 40px;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  cursor: pointer;
}

.hamburger span {
  display: block;
  height: 4px;
  background: white;
  z-index: -1;
}

.hamburger.open {
  transform: rotate(90deg);
}
