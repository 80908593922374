.basket_button {
  transition: 0.2s ease;
  position: fixed;
  width: 60px;
  height: 60px;
  background: white;
  border-radius: 50%;
  bottom: 1rem;
  right: 1rem;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  background: yellow;
  box-shadow: 0 2px 6px 3px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.basket_button svg {
  color: black;
}

.basket_button:hover {
  transform: scale(1.1);
}

.basket_item_count {
  background: rgb(240, 27, 27);
  color: white;
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 0.7rem;
  font-weight: bold;
  border-radius: 50%;
}

@media screen and (min-width: 960px) {
  .basket_button {
    display: none;
  }
}
