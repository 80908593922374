@import url(https://fonts.googleapis.com/css2?family=Carter+One&family=Lato:wght@400;700&display=swap);
/* 
font-family: 'Lato', sans-serif;
font-family: 'Carter One', cursive;
*/

body {
  margin: 0;
  font-family: "Lato", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
  color: yellow;
}

h1 {
  font-family: "Carter One", cursive;
  word-spacing: 0.8rem;
}

.lds-default {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-default div {
  position: absolute;
  width: 6px;
  height: 6px;
  background: rgb(64, 64, 64);
  border-radius: 50%;
  animation: lds-default 1.2s linear infinite;
}
.lds-default div:nth-child(1) {
  animation-delay: 0s;
  top: 37px;
  left: 66px;
}
.lds-default div:nth-child(2) {
  animation-delay: -0.1s;
  top: 22px;
  left: 62px;
}
.lds-default div:nth-child(3) {
  animation-delay: -0.2s;
  top: 11px;
  left: 52px;
}
.lds-default div:nth-child(4) {
  animation-delay: -0.3s;
  top: 7px;
  left: 37px;
}
.lds-default div:nth-child(5) {
  animation-delay: -0.4s;
  top: 11px;
  left: 22px;
}
.lds-default div:nth-child(6) {
  animation-delay: -0.5s;
  top: 22px;
  left: 11px;
}
.lds-default div:nth-child(7) {
  animation-delay: -0.6s;
  top: 37px;
  left: 7px;
}
.lds-default div:nth-child(8) {
  animation-delay: -0.7s;
  top: 52px;
  left: 11px;
}
.lds-default div:nth-child(9) {
  animation-delay: -0.8s;
  top: 62px;
  left: 22px;
}
.lds-default div:nth-child(10) {
  animation-delay: -0.9s;
  top: 66px;
  left: 37px;
}
.lds-default div:nth-child(11) {
  animation-delay: -1s;
  top: 62px;
  left: 52px;
}
.lds-default div:nth-child(12) {
  animation-delay: -1.1s;
  top: 52px;
  left: 62px;
}

@keyframes lds-default {
  0%,
  20%,
  80%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
}

.Toastify {
  display: none;
}

@media screen and (min-width: 960px) {
  .Toastify {
    display: block;
  }
}

.Layout_wrapper__267cj {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
}

.Layout_wrapper_top__24DfS {
  flex: 1 1;
}

.Layout_wrapper_bottom__2s3zv {
  padding: 2rem;
}

.Hamburger_hamburger__2_Z8F {
  transition: transform 0.4s ease;
  width: 40px;
  height: 40px;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  cursor: pointer;
}

.Hamburger_hamburger__2_Z8F span {
  display: block;
  height: 4px;
  background: white;
  z-index: -1;
}

.Hamburger_hamburger__2_Z8F.Hamburger_open__2-KeA {
  transform: rotate(90deg);
}

.Logo_logo__1Ev9K {
  flex: auto;
  font-size: 2rem;
  font-family: "Carter One", cursive;
}

.Logo_logo__1Ev9K a {
  color: yellow;
}

.MobileHeader_mobile_header__2tNSF {
  position: relative;
  /* background: black; */
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 50;
}

@media screen and (min-width: 960px) {
  .MobileHeader_mobile_header__2tNSF {
    display: none;
  }
}

.DesktopHeader_site_header__1XROG {
  display: none;
}

@media screen and (min-width: 960px) {
  .DesktopHeader_site_header__1XROG {
    transition: 0.2s ease;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    background: transparent;
    z-index: 50;
  }

  .DesktopHeader_site_header__1XROG a {
    display: inline-block;
    text-decoration: none;
    color: white;
  }

  .DesktopHeader_site_header__nav_container__3uZN5 {
    flex: 1 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .DesktopHeader_site_header__nav__2Gj6x a {
    padding: 1rem;
  }

  .DesktopHeader_site_header__user_auth__P0Pn5 {
    margin-left: 1rem;
  }

  .DesktopHeader_site_header__1XROG.DesktopHeader_sticky__pDUjl {
    position: -webkit-sticky;
    position: sticky;
    background-color: #fff200;
  }

  .DesktopHeader_site_header__1XROG.DesktopHeader_sticky__pDUjl a {
    color: black;
  }

  .DesktopHeader_site_header__1XROG.DesktopHeader_sticky__pDUjl
    .DesktopHeader_site_header__basket_link__1QZqt:hover
    .DesktopHeader_site_header__basket_quantity__3RkUX {
    background: black;
  }
}

.ContainerFlex_container_flex__2rCMx {
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  height: 100%;
}

/* basket button */

.ShoppingBasketButton_site_header__basket__HiCYx {
  padding: 0 1rem;
  position: relative;
  font-size: 1.7rem;
  margin-top: -0.5rem;
}

.ShoppingBasketButton_site_header__basket_quantity__1Ccax {
  position: absolute;
  width: 20px;
  height: 20px;
  font-size: 0.7rem;
  font-weight: bold;
  background: red;
  border-radius: 50%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0.5rem;
  right: 0.5rem;
}

.BasketButton_basket_button__2j-79 {
  transition: 0.2s ease;
  position: fixed;
  width: 60px;
  height: 60px;
  background: white;
  border-radius: 50%;
  bottom: 1rem;
  right: 1rem;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  background: yellow;
  box-shadow: 0 2px 6px 3px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.BasketButton_basket_button__2j-79 svg {
  color: black;
}

.BasketButton_basket_button__2j-79:hover {
  transform: scale(1.1);
}

.BasketButton_basket_item_count__2_Cvb {
  background: rgb(240, 27, 27);
  color: white;
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 0.7rem;
  font-weight: bold;
  border-radius: 50%;
}

@media screen and (min-width: 960px) {
  .BasketButton_basket_button__2j-79 {
    display: none;
  }
}

.Hero_hero__2jLCD {
  height: 250px;
  max-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  overflow: hidden;
  margin-top: -80px;
}

.Hero_hero_image__1yvtb {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.Hero_hero__mobile_image__31Z6f {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.Hero_hero__shopping_basket__G9ekg {
  height: 300px;
}

.Hero_hero__full_height__1LNy9 {
  height: 100vh;
  padding: 0;
}

.Hero_hero_text__1hDCI {
  position: relative;
  padding: 1rem;
  z-index: 1;
}

.Hero_hero_text__1hDCI p {
  color: white;
  margin-bottom: 1rem;
  line-height: 1.9;
  width: 100%;
}

.Hero_hero_text__footer__UfNy6 {
  margin-top: 1rem;
}

.Hero_hero_title__1EChn {
  font-size: 3rem;
  color: #fff;
  font-family: "Carter One", cursive;
}

.Hero_hero_subtitle__3-o3C {
  font-size: 1.4rem;
  color: #fff;
}

.Hero_hero_image__overlay__3MmCH {
  position: absolute;
  top: 0;
  left: 0;
  background: black;
  opacity: 0.3;
  height: 100%;
  width: 100%;
}

.Hero_hero__desktop_image__1-ruk {
  display: none;
}

.Hero_hero_video__O2714 {
  display: none;
}

@media screen and (min-width: 960px) {
  .Hero_hero__2jLCD {
    margin-top: -88px;
  }
  .Hero_hero_title__1EChn {
    font-size: 5rem;
  }

  .Hero_hero_image__1yvtb img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: 50% 10%;
  }

  .Hero_hero_video__O2714 {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .Hero_hero_video__media__22kzt {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
  }

  .Hero_hero_text__1hDCI {
    padding: 2rem 3rem 3rem;
  }

  .Hero_hero_text__1hDCI p {
    max-width: 600px;
  }

  .Hero_hero__desktop_image__1-ruk {
    display: block;
  }

  .Hero_hero__mobile_image__31Z6f {
    display: none;
  }
}

.Button_button__3QF_k {
  transition: 0.1s ease;
  display: inline-block;
  border: none;
  padding: 1rem 2rem;
  background: #fff200;
  color: black;
  cursor: pointer;
  border-radius: 30px;
}

.Button_button__3QF_k:hover {
  color: white;
  text-decoration: none;
  background: black;
}

.Section_section__Ap5Zy.Section_default__2yzIV {
  padding: 1.5rem;
}

.Section_section__Ap5Zy.Section_large__2tq3S {
  /* padding: 3rem 1.5rem; */
  padding: 0;
}

.Section_section__Ap5Zy.Section_xl__1gshX {
  padding: 6rem 3rem;
}

@media screen and (min-width: 695px) {
  .Section_section__Ap5Zy.Section_default__2yzIV {
    padding: 3rem;
  }

  .Section_section__Ap5Zy.Section_large__2tq3S {
    padding: 6rem 3rem;
  }

  .Section_section__Ap5Zy.Section_xl__1gshX {
    padding: 9rem 3rem;
  }
}

.Container_container__3RIox {
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  padding: 0 1rem;
}

.QuantityButtons_quantity_buttons__jUw-J {
  display: flex;
  justify-content: center;
  /* margin-top: 2rem; */
}

.QuantityButtons_quantity_button__2EGf6 {
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 1.5rem;
}

.QuantityButtons_quantity__1LzeN {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  background: white;
  font-size: 1.5rem;
  padding: 0 1rem;
  width: 60px;
  border-left: 1px solid white;
  border-right: 1px solid white;
  font-family: "Carter One", cursive;
}

button {
  background: black;
  color: white;
  font-size: 1.15rem;
  outline: none;
}

button:active {
  background: rgb(233, 233, 2);
  color: black;
}

@media screen and (min-width: 695px) {
  .QuantityButtons_quantity_buttons__jUw-J {
    margin-left: 3.5rem;
    margin-top: 0;
  }

  button {
    cursor: pointer;
  }
  button:hover {
    background: yellow;
    color: black;
  }
}

.AddToBasketButton_add_to_basket__button__2ZNCG {
  display: flex;
  align-self: center;
  align-items: center;
  border: none;
  background: rgb(19, 19, 19);
  padding: 0.5rem 2rem;
  border-radius: 30px;
  background: #22abfb;
  color: white;
  font-size: 1rem;
  font-family: "Carter One", cursive;
  margin-top: 2rem;
}

.AddToBasketButton_add_to_basket__button__2ZNCG svg {
  font-size: 1.25rem;
  margin-left: 0.5rem;
  margin-bottom: 0.1rem;
}

@media screen and (min-width: 695px) {
  .AddToBasketButton_add_to_basket__button__2ZNCG {
    margin-right: 1.5rem;
    margin-top: 0;
  }
}

.Toast_toast__28w0Q {
  display: flex;
  align-items: center;
}

.Toast_toast_details__2Kefn {
  display: inline-block;
  margin-left: 1rem;
}

.Toast_toast_details__2Kefn strong {
  display: block;
}

.AddToBasket_add_to_basket__Se8-n {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 2rem;
}

@media screen and (min-width: 695px) {
  .AddToBasket_add_to_basket__Se8-n {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

.PizzaPreview_pizza_preview__inner__i_Oeg {
  position: relative;
}

.PizzaPreview_pizza_image__3Uc_P {
  width: 150px;
  height: 150px;
}

.PizzaPreview_pizza_preview__flex__3FgD8 {
  flex-direction: column;
  align-items: center;
}

.PizzaPreview_pizza_details__u5bRL {
  display: inline-block;
  flex: 1 1;
  text-align: center;
}

.PizzaPreview_pizza_title__2kbfA {
  font-family: "Carter One", cursive;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  padding: 0.5rem 0.5rem;
  margin-bottom: 1rem;
  background: yellow;
  font-size: 1.4rem;
  margin-top: 2rem;
}

.PizzaPreview_pizza_title__2kbfA::before {
  content: "";
  position: absolute;
  transform: skewX(2deg);
  background: black;
  height: 100%;
  width: 100%;
  z-index: -1;
}

.PizzaPreview_pizza_ingredients__3M5xi {
  text-align: justify;
  padding: 0 0.5rem;
}

.PizzaPreview_pizza_price__2YDhO {
  text-align: center;
  font-family: "carter one", cursive;
  font-size: 2rem;
}

@media screen and (min-width: 695px) {
  .PizzaPreview_pizza_image__3Uc_P {
    width: 250px;
    height: 250px;
  }

  .PizzaPreview_pizza_preview__flex__3FgD8 {
    flex-direction: row;
  }

  .PizzaPreview_pizza_title__2kbfA {
    margin-top: 0;
  }

  .PizzaPreview_pizza_details__u5bRL {
    text-align: left;
    padding-left: 1rem;
  }
}

.Basket_basket_items__1N-X8 {
  display: flex;
  flex-direction: column;
}

.Basket_basket_items__title_container__2isEQ {
  width: 100%;
  transform: translateY(-9rem);
  padding: 0 1rem;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.Basket_basket_items__title__3WzwZ {
  display: inline-block;
  color: white;
  margin: 0;
  font-size: 1.25rem;
  font-family: "Lato", sans-serif;
  word-spacing: 2px;
}

.Basket_basket_items__title__3WzwZ small {
  font-size: 1rem;
  margin-left: 0;
  color: yellow;
}

.Basket_basket_items__total_price__nt2iH {
  font-size: 2rem;
  color: white;
  display: flex;
  align-items: flex-end;
}

.Basket_basket_items__total_price__nt2iH span {
  font-size: 1rem;
  color: yellow;
}

.Basket_basket_items__total_price__nt2iH p {
  margin: 0;
  margin-left: 0.5rem;
  line-height: 1;
}

.Basket_basket_items__container__1aZ1f {
  width: 100%;
  background: white;
  margin-top: -8rem;
  padding-top: 0.5rem;
}

.Basket_cart_empty__1HkrX {
  padding: 3rem;
  margin-top: -8rem;
  background: white;
  text-align: center;
}

.Basket_cart_empty__btn__3Wm0U {
  transition: 0.2s cubic-bezier(0.19, 1, 0.22, 1);
  color: white;
  background: black;
  padding: 1rem 2rem;
  display: inline-block;
  border-radius: 30px;
  font-family: "carter one", cursive;
  font-size: 1.5rem;
}

.Basket_basket_items__checkout__1NMcb {
  margin: 0 auto;
  width: 100%;
  max-width: 800px;
  padding: 1rem 0 0 0.5rem;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
}

.Basket_basket_items__checkout__1NMcb a {
  margin-left: auto;
  border-radius: 30px;
  padding: 0.5rem 1.5rem;
  border: none;
  font-size: 1.25rem;
  color: white;
  background: #22abfb;
}

.Basket_basket_items__checkout__1NMcb a:hover {
  background: yellow;
  color: black !important;
}

@media screen and (min-width: 695px) {
  .Basket_basket_items__1N-X8 {
    margin-bottom: 3rem;
  }

  .Basket_basket_items__title_container__2isEQ {
    max-width: 800px;
    margin: 0 auto;
  }

  .Basket_basket_items__title__3WzwZ {
    font-size: 2rem;
  }

  .Basket_basket_items__title__3WzwZ small {
    margin-left: 0.5rem;
  }

  .Basket_basket_items__total_price__nt2iH {
    font-size: 3rem;
  }

  .Basket_basket_items__total_price__nt2iH span {
    font-size: 1.25rem;
  }

  .Basket_basket_items__total_price__nt2iH p {
    margin-left: 1rem;
  }

  .Basket_basket_items__container__1aZ1f {
    padding-top: 0;
    max-width: 800px;
    margin: 0 auto;
    box-shadow: 0 2px 8px 2px rgba(200, 200, 200, 0.2);
    border-radius: 4px;
  }
}

.BasketItemTotal_basket_item__total__JoeEy {
  margin-left: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  align-self: stretch;
}

.BasketItemTotal_price_container__suqml {
  text-align: right;
  margin-right: 0.5rem;
  margin-top: 0.2s5rem;
}

.BasketItemTotal_price__3jW9k {
  font-size: 1.5rem;
  margin-bottom: 0;
}

.BasketItemTotal_quantity__g3WgW {
  color: rgb(167, 167, 167);
}

.RemoveItem_basket_item__delete__3MY0L {
  line-height: 1;
  font-size: 1rem;
}

.RemoveItem_basket_item__delete_btn__1sclj {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  color: rgb(226, 31, 31);
  font-size: 1.5rem;
}

.RemoveItem_basket_item__delete_btn__1sclj:hover {
  color: black;
  background: none;
}

.RemoveItem_basket_item__delete_btn__1sclj svg {
  margin-bottom: 0.15rem;
  margin-right: 0.5rem;
}

@media screen and (min-width: 695px) {
  .RemoveItem_basket_item__delete_btn__1sclj {
    font-size: 1rem;
  }
}

.BasketItem_basket_item__3Zj0v {
  display: none;
}

@media screen and (min-width: 695px) {
  .BasketItem_basket_item__3Zj0v {
    position: relative;
    display: flex;
    align-items: center;
    padding: 2rem;
  }

  .BasketItem_basket_item__3Zj0v::after {
    position: absolute;
    content: "";
    width: 95%;
    height: 1px;
    background: #e4e4e4;
    bottom: 0;
    left: 2.5%;
  }

  .BasketItem_basket_item__3Zj0v:last-child::after {
    display: none;
  }

  .BasketItem_basket_item__image__1c8LJ {
    width: 120px;
    height: 120px;
    margin-right: 2rem;
  }

  .BasketItem_basket_item__title__1XPCj {
    font-family: "Carter One", cursive;
  }
}

.BasketItemMobile_basket_item__mobile__ouS7U {
  position: relative;
  display: flex;
  align-items: center;
  padding: 1rem;
}

.BasketItemMobile_basket_item__mobile__ouS7U::after {
  position: absolute;
  content: "";
  width: 95%;
  height: 1px;
  background: #e4e4e4;
  bottom: 0;
  left: 2.5%;
}

.BasketItemMobile_basket_item__image__3KAVO {
  width: 80px;
  height: 80px;
  margin-right: 1rem;
}

.BasketItemMobile_basket_item__details__3kJxc {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.BasketItemMobile_basket_item__title__sCULK {
  font-size: 1.2rem;
}

.BasketItemMobile_basket_item__delete__33ILR {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

@media screen and (min-width: 695px) {
  .BasketItemMobile_basket_item__mobile__ouS7U {
    display: none;
  }
}

.LoadingScreen_loading_overlay__2x-XN {
  transition: 0.2s ease;
  background: #000;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  pointer-events: none;
}

.LoadingScreen_loading_overlay__2x-XN.LoadingScreen_show__uJtCu {
  opacity: 1;
}

.LoadingScreen_loading_overlay__2x-XN h4 {
  color: white;
  font-family: "carter one", cursive;
}

.LoadingScreen_lds_ring__25CbC {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.LoadingScreen_lds_ring__25CbC div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: LoadingScreen_lds_ring__25CbC 2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.LoadingScreen_lds_ring__25CbC div:nth-child(1) {
  animation-delay: -0.45s;
}
.LoadingScreen_lds_ring__25CbC div:nth-child(2) {
  animation-delay: -0.3s;
}
.LoadingScreen_lds_ring__25CbC div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes LoadingScreen_lds_ring__25CbC {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

