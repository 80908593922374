.mobile_header {
  position: relative;
  /* background: black; */
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 50;
}

@media screen and (min-width: 960px) {
  .mobile_header {
    display: none;
  }
}
