.quantity_buttons {
  display: flex;
  justify-content: center;
  /* margin-top: 2rem; */
}

.quantity_button {
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 1.5rem;
}

.quantity {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  background: white;
  font-size: 1.5rem;
  padding: 0 1rem;
  width: 60px;
  border-left: 1px solid white;
  border-right: 1px solid white;
  font-family: "Carter One", cursive;
}

button {
  background: black;
  color: white;
  font-size: 1.15rem;
  outline: none;
}

button:active {
  background: rgb(233, 233, 2);
  color: black;
}

@media screen and (min-width: 695px) {
  .quantity_buttons {
    margin-left: 3.5rem;
    margin-top: 0;
  }

  button {
    cursor: pointer;
  }
  button:hover {
    background: yellow;
    color: black;
  }
}
