.toast {
  display: flex;
  align-items: center;
}

.toast_details {
  display: inline-block;
  margin-left: 1rem;
}

.toast_details strong {
  display: block;
}
