.basket_item__total {
  margin-left: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  align-self: stretch;
}

.price_container {
  text-align: right;
  margin-right: 0.5rem;
  margin-top: 0.2s5rem;
}

.price {
  font-size: 1.5rem;
  margin-bottom: 0;
}

.quantity {
  color: rgb(167, 167, 167);
}
