.hero {
  height: 250px;
  max-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  overflow: hidden;
  margin-top: -80px;
}

.hero_image {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.hero__mobile_image {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.hero__shopping_basket {
  height: 300px;
}

.hero__full_height {
  height: 100vh;
  padding: 0;
}

.hero_text {
  position: relative;
  padding: 1rem;
  z-index: 1;
}

.hero_text p {
  color: white;
  margin-bottom: 1rem;
  line-height: 1.9;
  width: 100%;
}

.hero_text__footer {
  margin-top: 1rem;
}

.hero_title {
  font-size: 3rem;
  color: #fff;
  font-family: "Carter One", cursive;
}

.hero_subtitle {
  font-size: 1.4rem;
  color: #fff;
}

.hero_image__overlay {
  position: absolute;
  top: 0;
  left: 0;
  background: black;
  opacity: 0.3;
  height: 100%;
  width: 100%;
}

.hero__desktop_image {
  display: none;
}

.hero_video {
  display: none;
}

@media screen and (min-width: 960px) {
  .hero {
    margin-top: -88px;
  }
  .hero_title {
    font-size: 5rem;
  }

  .hero_image img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: 50% 10%;
  }

  .hero_video {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .hero_video__media {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
  }

  .hero_text {
    padding: 2rem 3rem 3rem;
  }

  .hero_text p {
    max-width: 600px;
  }

  .hero__desktop_image {
    display: block;
  }

  .hero__mobile_image {
    display: none;
  }
}
